import React from 'react'

export default function fpc() {
  return (
    <div style={{paddingLeft:'100px',paddingRight:'100px',paddingTop:'50px'}}>
    <h3 style={{display:"flex",justifyContent:'center',alignItems:'center'}}>FAIR PRACTICE CODE</h3>
    <div>
        <h4>1.INTRODUCTION</h4>
          <p> <strong>MONK CAPITAL PRIVATE LIMITED  ("Company")</strong> a Non-Banking Finance Company<strong>("NBFC")</strong> registered with Reserve Bank Of India<strong>("RBI")</strong>
            is presently in the business of providing different types of loans which includes personal loans, salary advance, semester loans, online purchase loan, and auto loans, to its various customers. Such credit facilities are extended to different types of customers, which include Individuals and Companies as of now, however, may include Partnership Firms, and other Legal entities in the future.</p>
            <p >
                The company has framed and adopted this Fair Practice Code (“Code” or “FPC”), which                        sets out the principles for fair practice standards when dealing with customers. As per the RBI directives, the Company has adopted and implemented this Code. The FPC has been duly approved by Board of Directors of the Company. This FPC applies to the all categories of products and services offered by the Company (currently offered or which may be introduced at a future date).
            </p>
            <p >
                The Company has developed the FPC for its lending operations based on the RBI guidelines, dealing and transparency in its businesses transactions.
            </p>
    </div>
    <div>
        <h4>
            2.PURPOSE
        </h4>
        <div>
            <ul>
                <li>To promote good and fair practices by setting minimum standards in dealing with customers;</li>
                <li>To increase transparency so that the customer can have a better understanding of what they scan reasonably expect of the services;</li>
                <li>To promote a fair and cordial relationship between customer and Company.</li>
            </ul>
        </div>
    </div>

    <div>
        <h4>3.KEY COMMITMENTS</h4>
        <p>
            The Company's key commitments to customers are as follows:
            <ul >
            <li >
                Act fairly and reasonably in all their dealings with customers by:
            </li>
            <ul >
                <li >Meeting the commitments and standards in this Code, for the products and services the Company offers and in the procedures and practices its staff / employees follows;</li>
                <li >Making sure that Company's products and services meet relevant laws and regulations in force in India;</li>
                <li >Company's dealings with customers will rest on ethical principles of integrity and transparency.</li>
            </ul>
            <li >Help customers understand how Company's product work by explaining their financial implications; and</li>
            <li >Deal quickly and sympathetically with things that go wrong by: </li>

            <ul >
            <li>Correcting mistakes quickly;</li>
            <li>Handling customer's grievances quickly; and</li>
            <li>Telling customers' how to take their complaint forward if they are still not satisfied with the resolution</li>
            </ul>
            <li>D.Publicize this Code by displaying it on Company's website <a href="https://www.feemonk.com/">(www.feemonk.com)</a>  and have copies available for customer on request.</li>

            </ul>

        </p>
    </div>

    <div>
        <h4>4.INFORMATION</h4>
        <div >
            <ul >
            <li class="mt-3">The Company shall help customer choose products and services, which meet their needs and give them clear information explaining the key features of the services and products of the Company they are interested in.</li>
            <li class="mt-3">Inform customers about the documentary information the Company needs from them to establish customer's true identity and address and other documents to comply with legal and regulatory requirements vis-à-	Know Your Customer</li>
            <li class="mt-3">The Company would provide information on interest rates, common fees and charges through:</li>  
            <ul>
             <li class="mt-2">Through telephones or help lines.</li>
             <li class="mt-2">Through designated staff/help desk at its office.</li>
             <li class="mt-2">Publishing on the website of the Company.</li>
            
            </ul>
            

        </ul>
        </div>
    </div>

    <div>
        <h4>5.APPLICATIONS FOR LOANS AND THEIR </h4>
        <p >PROCESSING The Company shall ensure that:</p>

        <ul>
            <li >All communications to the borrower will be made in English or any vernacular language.</li>
            <li >The loan application forms of the Company include necessary information which affect the interest of the borrower, so that a meaningful comparison with the terms and conditions offered by other NBFCs or lenders can be made, and informed decision can be taken by the borrower.</li>
            <li >The loan application form will clearly indicate the documents required to be submitted with the application form.</li>
            <li >The Company, before sanctioning the loan would assess the ability of the borrower to repay the loan.</li>
            <li >It issues acknowledgement over an email to the borrower for each application after the disbursement of the loan amount subject to receipt of the complete information as per standard application format. Additional information and support documents may occasionally be found necessary in course of processing a customer / borrower application.</li>
            <li >Applications complete in all respects would be processed within a reasonable time frame but within a period of 30 days from the date of receipt of duly completed Loan Application Forms together with the requisite documents complying with the prevailing rules and regulations by the borrower. In case the proposal is not approved by the Company, the borrower would be intimated accordingly.</li>

       
        </ul>



    </div>

    <div>
        <h4>6.LOAN APPRAISAL AND TERMS/CONDITIONS The Company will:</h4>
        <ul >
            <li>Convey in writing over an email to the borrower in the English or any vernacular language by means of sanction letter or otherwise, the amount of loan sanctioned along with the most important terms and conditions including annualized rate of interest, overdue interest, late payment charges and method of application (EMI schedule) thereof.</li>
            <li>Mention the penal interest charged for late repayment in the loan agreement</li>
            <li>Furnish a copy of the loan agreement preferably in a vernacular language or in English language as understood by the borrower along with a copy each of all enclosures quoted in the loan agreement to all the borrowers within 3 business days from the time of disbursement of loans on the account of the borrower / customer held by the customer withthe Company.</li>
        </ul>
    </div>

    <div>
        <h4>7.DISBURSEMENT OF LOANS INCLUDING CHANGES IN TERMS AND CONDITIONS</h4>
        <ul >
        <li >The borrower shall enter into a loan agreement containing all standard and specific terms and conditions with Company, execute the requisite documents, create security for the assistance as may be mentioned in the loan agreement / sanction letter, before seeking disbursement.</li>
        <li >The Company will give notice in writing or through electronic means to the borrower in a vernacular language or English language as understood by the borrower of any change in the terms and conditions including EMI schedule, interest rates, service charges, overdue interest charges etc.</li>
        <li >The Company shall also ensure that changes in interest rates and other charges are effected only prospectively. A suitable condition in this regard should be incorporated in the loan agreement.</li>
        <li >Decision to recall / accelerate payment or performance under the arrangement will be in consonance with the loan agreement.</li>
        <li >The Company will release all securities (if any) on repayment of all dues or on realization of the outstanding amount of loan subject to any legitimate right or lien for any other claim the Company may have against borrower. If such right of set off is to be exercised, the borrower will be given notice about the same with full particulars about the remaining claims and the conditions under which the Company is entitled to retain the securities till the relevant claim is settled/paid.</li>


        </ul>
    </div>

    <div>
        <h4>8.COLLECTION OF DUES</h4>
        <ul>
        <li >Whenever loans are given, Company would explain to the customer the repayment process by way of amount, tenure and periodicity of repayment mentioned in the EMI Schedule. However, if the customer does not adhere to repayment schedule, a defined process in accordance with the laws of the land shall be followed for recovery of dues. The process will involve reminding the customer by sending the borrower notices over email or through calls or SMS or by making personal visits and / or repossession of security, if any.</li>
        <li >Company staff or any person or any third party authorized to represent the Company in collection of dues or/and security repossession shall identify himself / herself and display the authority letter issued by the Company and upon request, display his/her identity card issued by the Company or an authorized person under authority of the Company. Company shall provide the customers with all the information regarding overdue. The staff shall be adequately trained to deal with the customers in an appropriate manner.</li>
        <li >
            During visits to customer's place by the person authorized by Company for dues collection or/and security possession/repossession the following guidelines shall be followed: </li>
            
            <ul >
            <li >Customer would be contacted ordinarily at the place of his / her choice absence of any specified place at the place of his / her residence and if at his / her residence, at the place of business / occupation.</li>
            <li >Identity and authority to represent the Company shall be made known at the first instance.</li>
            <li >Customer's privacy should be respected</li>
            <li >Interaction with the customer shall be in a civil manner.</li>
            <li >The Company representatives shall contact the customers between 0700 hrs and 1900 hrs unless the special circumstances of the customer's business or occupation may otherwise so require.</li>
            <li >Time and number of calls and contents of conversation would be documented.</li>
            <li >All assistance should be given to resolve disputes or differences in a mutually acceptable and orderly manner.</li>
            <li >During visits to customer's place for dues collection, decency and decorum shall be maintained.</li>
            <li >The board approved collections policy of the Company shall be adhered in the collection process.</li>

        
        </ul>
       
        
        </ul>
    </div>

    <div>
        <h4>9.GENERAL</h4>
        <ul >
        <li >The Company will refrain from interference in the affairs of the borrower except for the purposes provided in the terms and conditions of the loan agreement (unless new information, not earlier disclosed by the borrower, has come to the notice of the Company).</li>
        <li >In case of receipt of request from the borrower for transfer of borrowed account, the consent or otherwise, objection of the Company, if any, will be conveyed within 21 days from the date of receipt of request. Such transfer shall be as per transparent contractual terms in consonance with law.</li>
        <li >In the matter of recovery of loans, the Company will not resort to undue harassment viz. persistently bothering the borrowers at odd hours, use of muscle power for recovery of loans, etc. To avoid rude behavior from the staff of the Company, the Company shall ensure that the staff are adequately trained to deal with the customers in an appropriate manner.</li>
        <li >The Company may not reveal transaction details of the borrowers to any other persons except under the following circumstances:</li>

        <ul >
            <li >The information is required to be disclosed by any applicable law, any direction, request or requirement of Government authority.</li>
            <li >The information is required by the auditor, professional advisors, agents or any third- party service providers of the lenders who are under duty of confidentiality.</li>
            <li >The information is required by any person with which the lender may enter into any transfer, assignment, participation or other agreements.</li>
            <li >If the information is required by other banks if the borrower has availed any facility from them or any credit information bureau.</li>

        
        </ul>
            
        
        </ul>
    </div>

    <div>
        <h4>10.FURTHER ASSISTANCE</h4>
        <ul >
            <li >A grievance redressal mechanism within the organization have been set up to resolve disputes arising in this regard. This mechanism will ensure that all the disputes arising out of the decisions the Company's functionaries are heard and diposed of atleast at the next higher level. The details of grievance redressal policy and grievance redressal officer is available on the Company website and the link of the same shall be sent to the borrowers over an email.</li>
            <li >There will be a periodical review of the compliance of the Fair Practices Code and the functioning of the grievance’s redressal mechanism at various levels of management.</li>
            <li >If the complaint / dispute is not redressed within a period of one month, the customer may appeal to the NBFC Ombudsman under whose jurisdiction the registered office of the Company falls at:
<p>
NBFC Ombudsman,<br/>
C/o Reserve Bank of India, Fort Glacis, Chennai 600 001<br/>
Telephone No: +91-44-25395964<br/>
Fax No: 25395488<br/>
Email: nbfcochennai@rbi.org.in
</p>

            </li>


        </ul>

    </div>

    <div>
        <h4>11.REGULATION OF RATE OF INTEREST</h4>
        <p >A.The Board of Directors has adopted an interest rate model for determining the rate of interest to be charged on loans and advances, processing and other charges taking into account relevant factors such as, cost of funds, margin and risk premium, etc. The rate of interest and the approach for gradations of risk and rationale for charging different rate of interest to different categories of borrowers shall be as per the Interest Rate Policy and shall be disclosed on the Company website.</p>
        <p >B.The Company provided to the Borrower annualized rates so that the borrower is aware of the exact rates that would be charged to the account.</p>

    </div>

</div>
  )
}
