import Navbar from './Navbar'
import Hero from './Hero'
import React, { useState } from "react";
import moment from 'moment';
import { createRoot } from 'react-dom/client';
import App1 from './Reciept/App1'
import Educationcard from '../Home/Educationcard';
import {useDispatch,useSelector} from 'react-redux'
import {userActions} from '../../actions/user.actions'
import { Table } from 'reactstrap';
import { Container, Button, Grid, Paper, Box, colors , Typography, Divider} from "@mui/material";
import { Homestyle } from "../Home/Style";
import EMI from "../../Assets/images/EMI.png"
import back from "../../Assets/images/arrow-left-circle.png"
import {useNavigate,useLocation} from 'react-router-dom'
import wallet from '../../Assets/images/wallet.png'
import axios from 'axios';
import icon from "../../Assets/images/i-icon.png"
import Tooltip from '@mui/material/Tooltip';
import download from "../../Assets/images/download.png"
import "./Repayments.css"
import App from './Statement/App';
import { jwtDecode } from "jwt-decode";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Footer from '../Home/Footer'
 
export default function Index() {
  const user = sessionStorage.getItem("token");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const location=useLocation();
  const status=location?.state?.loanStatus
  const getEmiHistory=useSelector((state)=>state?.emiHistory?.getEmiHistoryData?.data?.emiHistory?.data)
  const [emiHistory,setEmiHistory]=useState(null)
  const [count, setCount] = useState(0); 
  const [totalCount, setTotalCount] = useState(0);
  const dispatch=useDispatch()
  const urlGetEmiHistory=`/repayments/emi-history/${sessionStorage.getItem('loanId')}`
  const [repaymentdetails,setRepaymentDetails] = useState([])
  const decode = jwtDecode(user)
  
  React.useEffect(() => {
    const repaymentsUrl = `${process.env.REACT_APP_DASHBOARD_URL}/loan-repayment/all-repayments?loanId=${sessionStorage.getItem("LoanId")}`
         
    axios.get(repaymentsUrl,{
        headers:{
          Authorization:`Bearer ${user}`
      }
      }).then((res)=>{console.log("res",res?.data?.data)
      setRepaymentDetails(res?.data?.data)

    }).catch((error)=>console.log(error),"error")

  }, [])

  
  
  const navigate=useNavigate();

  function formatNumberWithCommas(number) {
    return number?.toLocaleString('en-IN'); 
  }
      
  const handleClick = (component, componentName) => {
    const newWindow = window.open("", "_blank");
    const container = newWindow.document.createElement("div"); // Creating a container element
    newWindow.document.body.appendChild(container); // Appending container to body
    createRoot(container).render(component); // Rendering into the container using createRoot
    setTimeout(() => {
      newWindow.close();
    }, 1000);
  };

  return (
    <div className='home-bg ' >

        <Navbar/>

       <Homestyle>
        <Container>
          <Paper className="paper-setting">
          <Grid container >
              <Grid item xs={6} sm={10} md={10} lg={10}>
                <div
                  onClick={() => navigate(-1)}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "5rem",
                  }}
                >
                  <img style={{ height: "22px", width: "22px" }} src={back} />
                  <p
                    style={{
                      fontFamily: "Inter-Medium",
                      fontSize: "14px",
                      fontWeight: "500",
                      padding: 0,
                      margin: 0,
                      paddingLeft: "5px",
                    }}
                  >
                    Back
                  </p>
                </div>
              </Grid>
             
            </Grid>
            <Grid container>
            <Grid item xs={6} sm={4} md={3}>
              <Typography variant="body2" component="div" className="payment" style={{fontWeight:'bold',color:'black',fontSize:'1rem',paddingTop:'1rem',paddingBottom:'1rem'}}>
               Receipts
              
              </Typography>
            </Grid>
            
          </Grid>
            {repaymentdetails?.filter(item => item?.repaymentAmount !== 0)?.map((item,index)=>(
              <div>
                <Accordion style={{
                            width: "100%",
                            overflow: "auto",
                            border: "1px solid #F9D8D6",
                            boxShadow: "0px 3px 3px 0px #D320281A",
                            borderRadius: "0.5rem",paddingLeft:'1rem',marginTop:'0.5rem'
                          }}>
                  <Grid container>
                    <Grid item xs={4} style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:"center"}}>
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "0.8rem",
                      }}
                    >
                      Amount
                    </p>
                    <p
                      style={{
                        fontFamily: "Inter-Medium",
                        fontSize: "0.8rem",
                        lineHeight: "1px",
                        fontWeight: "300",
                      }}
                    >
                      ₹{formatNumberWithCommas(item?.paymentAmount)}
                    </p>
                    </Grid>
                    <Grid item xs={4} style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:"center"}}>
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "0.8rem",
                      }}
                    >
                      Paid Date
                    </p>
                    <p
                      style={{
                        fontFamily: "Inter-Medium",
                        fontSize: "0.8rem",
                        lineHeight: "1px",
                        fontWeight: "300",
                      }}
                    >
                      {(item?.paidAt).substr(0,10)
                          ? new Date(item.paidAt).toLocaleDateString("en-GB") 
                          : null}
                    </p>
                    </Grid>
                    {/* <Grid item xs={3}>
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "0.8rem",
                      }}
                    >
                      Amount
                    </p>
                    <p
                      style={{
                        fontFamily: "Inter-Medium",
                        fontSize: "0.8rem",
                        lineHeight: "1px",
                        fontWeight: "300",
                      }}
                    >
                      ₹{formatNumberWithCommas(item?.repaymentAmount)}
                    </p>
                    </Grid> */}
                    <Grid
                      item
                      xs={4} style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:"center",marginTop:'0.5rem'}}
                    >
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: "0.8rem"
                        }} onClick={() =>
                          handleClick(
                            <App1
                              receipt={{
                                ...item,
                              }}
                              type="repayment"
                            />,
                            "Receipt"
                          )
                        }
                      >
                        Receipt
                      </p>
                       
                          <App1
                            type="repayment"
                            receipt={{
                              ...item, 
                              // firstname: item.firstName,
                              // loanType: loanDetails.courseName,
                              // lastname: loanDetails?.lastName,
                              // loanId: sessionStorage.getItem("LegacyLoanId")
                            }}
                          />
                      
                    </Grid>
                  </Grid>
                </Accordion>
              </div>
            ))}
          </Paper>
        </Container>
      </Homestyle>
     
        <Footer/>
    </div>
  )
}