import React, { useState, useEffect } from "react";
import axios from "axios";
import MuiButton from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../actions/user.actions";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Grid, Box, Typography, Divider, Paper } from "@mui/material";
import { Mainpagestyle } from "./Style";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Educationcard from "../Home/Educationcard";
import "./Applications.css";
import rejected from "../../Assets/images/rejected.png";
import back from "../../Assets/images/arrow-left-circle.png";
import close from "../../Assets/images/close.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Basicdetailsclicked from "../../Assets/images/Basicdetailsclicked.png";
import basicdetails from "../../Assets/images/basicdetails.png";
import kycclicked from "../../Assets/images/kycclicked.png";
import kyc from "../../Assets/images/kyc.png";
import incomeclicked from "../../Assets/images/incomeclicked.png";
import income from "../../Assets/images/income.png";
import loan from "../../Assets/images/loan.png";
import loandetailsclicked from "../../Assets/images/loandetailsclicked.png";
import disbursal from "../../Assets/images/dibursal.png";
import disbursalclicked from "../../Assets/images/disbursalclicked.png";
import sanction from "../../Assets/images/sanction1.png";
import sanctionclicked from "../../Assets/images/sanctionclicked.png";
import tickicon from "../../Assets/images/tickicon.png";
import InputText from "../../pay-later/components/atoms/InputText";
import FeemonkText from "../../pay-later/components/atoms/FeemonkText";
import Button from "../../pay-later/components/atoms/Button";
import styles from "../../pay-later/pages/WorkDetails/index.module.css";
import styles1 from "../../pay-later/pages/Sanction/index.module.css";
import Dropzone from "react-dropzone";
import SanctionLetter from "../../pay-later/pages/SanctionLetter";
import download from "../../Assets/images/download.png";
import Label from "../../pay-later/components/atoms/Label";
import check_circle from "../../Assets/images/check-circle.svg";
import Mandate from "./Mandates";
import rupee from "../../Assets/images/rupee.png";
import calender from "../../Assets/images/calender.png";
import utr from "../../Assets/images/utr.png";
import { jwtDecode } from "jwt-decode";
import feemonk from "../../Assets/images/FeeMonk_HighRes_Logo.png";
import styless from "./styles.module.css";
import * as moment from "moment";
import styless1 from "../../pay-later/pages/Sanction/index.module.css";
import BasicDetails from "./Components/BasicDetails";
import WorkDetails from "./Components/WorkDetails";
import Sanctions from "./Components/Sanctions";
import axiosInstance from "../../helpers/axios";
import Kyc from "./Components/Kyc";
import Disbursed from "./Components/Disbursed";
import FinalMessage from "./Components/FinalMessage/FinalMessage";

function Applications() {
  const user = sessionStorage.getItem("token");
  const [isImageClicked1, setIsImageClicked1] = useState(false);
  const [isImageClicked2, setIsImageClicked2] = useState(false);
  const [isImageClicked3, setIsImageClicked3] = useState(false);
  const [isImageClicked4, setIsImageClicked4] = useState(false);
  const [isImageClicked5, setIsImageClicked5] = useState(false);
  const [open, setOpen] = useState();
  const [showCards1, setShowCards1] = useState(false);
  const [showCards2, setShowCards2] = useState(false);
  const [showCards3, setShowCards3] = useState(false);
  const [showCards4, setShowCards4] = useState(false);
  const [showCards5, setShowCards5] = useState(false);
  const [openVerify, SetOpenVerify] = useState(true);
  const [showEditField, setShowEditField]  = useState('')
  const [openAddress, SetOpenAddress] = useState(false);
  const [openNext, SetOpenNext] = useState(false);
  const [isMandateVisible, setIsMandateVisible] = useState(false);
  // const [authToken, setAuthToken] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [dob, setDob] = useState("");
  const [salary, setSalary] = useState("");
  const [employerName, setEmployerName] = useState("");
  const [typeOfBusiness, setProfession] = useState("");
  const [incomePerMonth, setMonthlyIncome] = useState("");
  const [currentAddress, setCurrentAddress] = useState("yes");
  const [employmentType, setEmploymentType] = useState(
    sessionStorage.getItem("employmentType")
  );

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [fibUrl, setFibUrl] = useState("");
  const [instituteName, setInstituteName] = useState("");
  const [studentName, setStudentName] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseFee, setCourseFee] = useState("");
  const [applicantEmail, setApplicantEmail] = useState("");
  const [stage, setStage] = useState('')
  const [applicationData, setApplicationData] = useState({})
  console.log(showEditField, 'editfield')
  const getApplicationStage = async (id) => {
    try {
      const response = await axiosInstance( `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${id}`)      
      setStage(response?.data?.data?.stage)
      console.log(response?.data?.data?.stage, 'stage')
    } catch (error) {
      console.log(error)
    }

  }


  const handleKYCClick = (item) => {
    setIsMandateVisible(!isMandateVisible);

    const url = `${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/generate-token`;
    const data = {
      mobile: item?.obj?.data?.mobile,
      userId: item?.obj?.data?.userId,
      applicationId: item?.obj?.applicationProfile?.applicationId,
    };
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
        sessionStorage.setItem("authToken", res?.data?.data);
      })
      .catch((error) => alert(error?.response?.data?.message));
  };

  const [loadingFibe, setLoadingFibe] = useState(false);
  const handleFibKYCClick = (item) => {
    setLoadingFibe(true);
    const url = `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/fibe-profile`;
    const data = {
      applicationId: item?.obj?.data?.applicationId,
      fundCode: item?.obj?.applicationProfile?.fundSourceCode,
    };
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
        console.log("fib token", res?.data?.data?.bitlyUrl);
        setLoadingFibe(false);
        setFibUrl(res?.data?.data?.bitlyUrl);
        if (res?.data?.data?.bitlyUrl) {
          window.open(res?.data?.data?.bitlyUrl, "_blank");
        } else {
          console.error("Invalid URL for KYC");
        }
      })
      .catch((error) => alert(error?.response?.data?.message));
  };

  const handleToggle = (id, item) => {
    sessionStorage.setItem("employmentType", item?.obj?.data?.employmentType);
    toggle(id);
    if (id === "1") {
      setShowCards1(true);
      setIsImageClicked1(true);
    } else {
      setShowCards1(false);
      setIsImageClicked1(false);
    }
    if (id === "2") {
      setShowCards2(true);
      setIsImageClicked2(true);
    } else {
      setShowCards2(false);
      setIsImageClicked2(false);
    }
    if (id === "3") {
      setShowCards3(true);
      setIsImageClicked3(true);
    } else {
      setShowCards3(false);
      setIsImageClicked3(false);
    }
    if (id === "4") {
      setShowCards4(true);
      setIsImageClicked4(true);
    } else {
      setShowCards4(false);
      setIsImageClicked4(false);
    }
    if (id === "5") {
      setShowCards5(true);
      setIsImageClicked5(true);
    } else {
      setShowCards5(false);
      setIsImageClicked5(false);
    }
  };
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const navigate = useNavigate();

  const [applicationsDetails, setApplicationDetails] = React.useState([]);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [ckycData, setCkycData] = useState(null);
  const [consentLink, setLink] = useState(null);
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
  });

  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, err);
    } else {
      console.log("Geolocation not supported");
    }
  }

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    setLocation({
      latitude,
      longitude,
    });
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
  }

  function err() {
    console.log("Unable to retrieve your location");
  }

  const dispatch = useDispatch();
  const applicationState = useSelector(
    (state) => state?.GETAPPLICATIONS?.getApplicationsData
  );
  const token = sessionStorage.getItem("token");
  const decode = jwtDecode(token);

  const urlApplications = `${process.env.REACT_APP_DASHBOARD_URL}/users/basic-info?searchField=${decode?.mobile}`;
  useEffect(() => {
    if (applicationState) {
      console.log("appp", applicationState?.data?.data);
      setApplicationDetails(applicationState?.data?.data);

      setTimeout(() => {
        const fibeApplication = applicationState?.data?.res?.filter(
          (item) =>
            item?.obj?.applicationProfile?.fundSourceCode === "FIB" &&
            item?.obj?.applicationProfile?.status !== "Dropped" &&
            item?.obj?.applicationProfile?.status !== "Rejected" &&
            item?.obj?.applicationProfile?.status !== "Disbursed"
        );
        console.log(fibeApplication, "FIBE");
        if (fibeApplication && fibeApplication.length > 0) {
          setLoadingFibe(false);
          const url = `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/fibe-profile`;
          const data = {
            applicationId: fibeApplication[0]?.obj?.data?.applicationId,
            fundCode:
              fibeApplication[0]?.obj?.applicationProfile?.fundSourceCode,
          };
          axios
            .post(url, data, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            })
            .then((res) => {
              setLoadingFibe(true);

              if (res?.data?.data?.bitlyUrl) {
                window.open(res?.data?.data?.bitlyUrl, "_blank");
              } else {
                console.error("Invalid URL for KYC");
              }
            })
            .catch((error) => alert(error?.response?.data?.message));
        } else {
          setLoadingFibe(true);
        }
      }, 1000);
    } else {
      dispatch(userActions.getApplications(urlApplications, token));
    }
  }, [applicationState]);

  const getPanPro = () => {
    const panUrl = "/pan-pro";
    const body = {
      panNumber: panNumber,
      userId: decode?.userId,
      applicationId: decode?.applicationId,
      dateOfBirth: moment(dob, "YYYY-MM-DD").format("DD/MM/YYYY"),
    };
    axios
      .post(process.env.REACT_APP_DASHBOARD_URL + panUrl, body)
      .then((res) => {
        if (res?.data?.data?.user_address?.full?.length > 0) {
          setCustomerDetails(res?.data?.data);
        } else {
          const ckycUrl = "/ckyc/create";
          const ckycBody = {
            userId: decode?.userId,
            panNumber: panNumber,
            dateOfBirth: moment(dob, "YYYY-MM-DD").format("DD/MM/YYYY"),
          };
          axios
            .post(process.env.REACT_APP_DASHBOARD_URL + ckycUrl, ckycBody)
            .then((res) => {
              setCkycData(res?.data?.data);
            })
            .catch((err) => {
              alert("Error while fetching CKYC");
            });
        }
        handleStartSession(res?.data?.data);
      })
      .catch((err) => {
        alert("Enter Correct Details (PAN / D.O.B)");
      });
  };

  const showNext = () => {
    SetOpenVerify(false);
    SetOpenNext(true);
    SetOpenAddress(false);
  };
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [agreementModal, setAgreementModal] = useState(false);

  const [mandateModal, setMandateModal] = useState(false);
  const [sanctionLetter, setSanctionLetter] = useState(null);
  const [agreement, setAgreement] = useState(null);
  const [sanctionUrl, setSanctionUrl] = useState(null);

  const toggle2 = (item) => {
    if (!modal1) {
      setSanctionLetter(item?.obj?.applicationProfile?.sanctionLetter);
      const url = `/file/url-content?path=${item?.obj?.applicationProfile?.sanctionLetter}`;
      axios
        .get(process.env.REACT_APP_DASHBOARD_URL + url, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
          setSanctionUrl(res?.data?.data);
        });
    }
    setModal1(!modal1);
  };
  const [agreementUrl, setAgreementUrl] = useState(null);

  const toggleAgreement = (item) => {
    if (!agreementModal) {
      setAgreement(item?.obj?.applicationProfile?.agreementUrl);
      const url = `/file/url-content?path=${item?.obj?.applicationProfile?.agreementUrl}`;
      axios
        .get(process.env.REACT_APP_DASHBOARD_URL + url, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
          setAgreementUrl(res?.data?.data);
        });
    }

    setAgreementModal(!agreementModal);
  };

  const toggleMandate = () => setMandateModal(!mandateModal);

  const [mandateModals, setMandateModals] = useState(false);
  const toggleMandates = () => setMandateModals(!mandateModals);

  const acceptSanctionLetter = () => {
    const userId = sessionStorage.getItem("userId");
    const appId = sessionStorage.getItem("appId");
    const url = `/integrations/update-applicant-consent?userId=${userId}&applicationId=${appId}&status=true`;

    axios.get(process.env.REACT_APP_DASHBOARD_URL + url).then((res) => {
      console.log(res);
    });
  };
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setShowEditField('')
  };

  const updateWorkDetails = () => {
    setShowCards2(false);
    setIsImageClicked2(false);

    setShowCards3(true);
    setIsImageClicked3(true);
    // const url = `/users/employment-details/update`;
    // const data = {
    //   employmentType:employmentType,
    //   employerName: employerName || document.getElementById("emplName")?.value,
    //   salary: salary || document.getElementById("salary")?.value,
    //   incomePerMonth: incomePerMonth,
    //   typeOfBusiness: typeOfBusiness,
    // };

    // axios.post(process.env.REACT_APP_DASHBOARD_URL + url,data,{
    //   headers: {
    //     Authorization: `Bearer ${user}`,
    //     'Content-type': 'application/json',
    //   },
    // })
    // .then((res)=>{
    //   console.log(res,"empployement data")
    // })
  };

  const updateProfileDetails = () => {
    setShowCards1(false);
    setIsImageClicked1(false);

    setShowCards2(true);
    setIsImageClicked2(true);
    // const url = `/users/profile-details/update`;
    // const data = {
    //   userId:sessionStorage.getItem("userId"),
    //   currentAddress: address || document.getElementById("address")?.value,
    //   currentCity: city || document.getElementById("city")?.value,
    //   currentState: state || document.getElementById("state")?.value,
    //   currentPincode: pincode || document.getElementById("pincode")?.value,
    // };

    // axios.post(process.env.REACT_APP_DASHBOARD_URL + url,data,{
    //   headers: {
    //     Authorization: `Bearer ${user}`,
    //     'Content-type': 'application/json',
    //   },
    // })
    // .then((res)=>{
    //   console.log(res,"empployement data")
    // })
  };

  const handleDisbursementDetailsClick = () => {
    setIsImageClicked5(true);
    setShowCards5(true);
    setIsImageClicked3(false);
    setShowCards3(false);
    setIsImageClicked2(false);
    setShowCards2(false);
    setIsImageClicked1(false);
    setShowCards1(false);
    setIsImageClicked4(false);
    setShowCards4(false);
  };

  const handleKycDetails = () => {
    setIsImageClicked5(false);
    setShowCards5(false);
    setIsImageClicked3(true);
    setShowCards3(true);
    setIsImageClicked2(false);
    setShowCards2(false);
    setIsImageClicked1(false);
    setShowCards1(false);
    setIsImageClicked4(false);
    setShowCards4(false);
  };

  const [toggleConsent, setToggleConsent] = useState(false);

  const handleStartSession = (item) => {
    const randomGen =
      Date.now().toString(36) + Math.random().toString(36).substr(2);

    window
      .getBureauSession(
        "708587bad0904485abe1127847dd62cd",
        randomGen,
        item.user_full_name_split[0].trim(),
        "",
        item.user_full_name_split[2].trim(),
        decode?.mobile
      )
      .then((res) => {
        console.log(res);
        setToggleConsent(true);
        setLink(res);
      });
  };

  const handleLoadSession = async () => {
    const result = await window.startBureauSession();
    if (result) {
      switch (result.status) {
        case "SUCCESS":
          const headers = {
            Authorization: `Bearer ${user}`,
            "Content-Type": "application/json",
          };

          const data = {
            mobile: decode?.mobile,
            firstName: customerDetails
              ? customerDetails.user_full_name_split[0].trim()
              : ckycData?.fullName?.split(" ")[1],
            lastName: customerDetails
              ? customerDetails.user_full_name_split[2].trim()
              : ckycData?.fullName?.split(" ")[2],
            instituteName: instituteName,
            // midName:customerDetails.midname,
            studentName: studentName,
            dateOfBirth: dob,
            courseName: courseName,
            courseFees: courseFee,
            gender: customerDetails
              ? customerDetails.user_gender === "M"
                ? "Male"
                : "Female"
              : ckycData?.gender === "M"
              ? "Male"
              : "Female",
            panId: customerDetails
              ? customerDetails.pan_number
              : ckycData?.panNumber,
            aadhaarId: customerDetails
              ? customerDetails.masked_aadhaar
              : ckycData?.indentityList?.filter(
                  (item) => item.name === "E-KYC Authentication"
                )[0].id,
            email: customerDetails
              ? customerDetails?.user_email
                ? customerDetails?.user_email
                : applicantEmail
              : ckycData?.email,
            currentAddress:
              customerDetails && customerDetails?.user_address?.full
                ? customerDetails?.user_address?.full
                : ckycData?.currentAddress,
            currentCity:
              customerDetails && customerDetails?.user_address?.city
                ? customerDetails?.user_address?.city
                : ckycData?.currentCity,
            currentState:
              customerDetails && customerDetails?.user_address?.state
                ? customerDetails?.user_address?.state
                : ckycData?.currentState,
            currentPincode:
              customerDetails && customerDetails?.user_address?.zip
                ? customerDetails?.user_address?.zip
                : ckycData?.currentPincode,
            panImage: " ",
            aadhaarFrontImage: " ",
            aadhaarBackImage: " ",
            isCoapplicant: false,
            relatedTo: " ",
            employmentType: " ",
            employerName: " ",
            salary: " ",
            incomePerMonth: " ",
            typeOfBusiness: " ",
            salesperson: " ",
            loanTenure: " ",
            ocrId: "",
            channel: 4,
          };
          handleLocationClick();
          axios
            .post(
              `${process.env.REACT_APP_DASHBOARD_URL}/summary/create`,
              data,
              { headers }
            )
            .then((response) => {
              const userId = response.data.data;

              if (userId) {
                const data2 = {
                  userId,
                  latitude: location.latitude,
                  longitude: location.longitude,
                };

                axios
                  .post(
                    `${process.env.REACT_APP_DASHBOARD_URL}/end-user/submit`,
                    data2,
                    { headers }
                  )
                  .then(() => {
                    setToggleConsent(false);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => console.log("error", error));
              }
            })
            .catch((error) => console.log("error", error));

          break;
        case "EXIT":
          alert("Retry Submit");
          toggle();
          break;
        case "ERROR":
          alert("Error Please Try Later");
          toggle();
          break;
        default:
          alert("Contact our team for assistance");
          break;
      }
    }
  };

  const handleApplicationData = async (userId, applicationId) => {
    try {
      const response = await axiosInstance( `${process.env.REACT_APP_DASHBOARD_URL}/summary/user?id=${userId}&applicationId=${applicationId}`)      
      setApplicationData(response?.data?.data)
      console.log(response?.data?.data, 'application data')
    } catch (error) {
      console.log(error)
    }
  }

  const handleNewApplicationCreation = () => {
    const body={
      mobile: decode?.mobile,
      channelId:'2',
      flow:'pay-later',
      userId: decode?.userId,
      applicationId: applicationsDetails?.pop()?.applicationId
    }
    const sendBody = btoa(JSON.stringify(body))
    window.open(`${process.env.REACT_APP_PAYLATER_FRONTEND}/pan-details/${sendBody}`, '_self')
  }

  return (
    <>
      <Mainpagestyle>
        {loadingFibe ? (
          <Box my={3}>
            <MuiButton variant="outlined" endIcon={<AddIcon size='small'/>} sx={{marginBottom:3, border:'1px solid #D32028', color:'#D32028', fontFamily:"Outfit", textTransform:'none','&:hover': {border:'1px solid #D32028'},width:'15rem'}} onClick={handleNewApplicationCreation}>New Application</MuiButton>
            <Typography variant="body2" component="div" className="payment">
              <span style={{ fontFamily: "Outfit-Medium",fontSize: "1em", whiteSpace: "nowrap",borderLeft:'4px solid #D32028', paddingLeft:'5px' }} >
                Active Applications
              </span>
            </Typography>

            {applicationsDetails?.map((item, index) => {
              return (
                <>

                  {item && Object.keys(item).length !== 0 && (
            
                        <div className="loan-payment-crd1">
                          <Accordion
                            style={{
                              backgroundColor: "#FFF8F4",
                              width: "100%",
                              overflow: "auto",
                              padding: "0.2rem",
                            }}
                            onClick={() => {
                              sessionStorage.setItem(
                                "appId",
                                item?.applicationId
                              );
                              sessionStorage.setItem(
                                "userId",
                                item?.userId
                              );
                              sessionStorage.setItem(
                                "mobile",
                                item?.mobile
                              );
                              getApplicationStage(item?.applicationId)
                              handleApplicationData(item?.userId, item?.applicationId)
                            }}
                            expanded={expanded === index}
                            onChange={handleChange(index)}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Grid
                                container
                                spacing={3}
                                style={{ alignItems: "center" }}
                              >
                                <Grid item xs={12} sm={6} md={6} lg={3}>
                                  <Typography
                                    variant="body2"
                                    component="div"
                                    className="Tuviksh1"
                                  >
                                    {item?.applicationId}
                                  </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} md={6} lg={4}>
                                  <Typography
                                    variant="body2"
                                    component="div"
                                    className="Program"
                                  >
                                    Start Date :{" "}
                                    {item.appliedOn
                                      ? moment(item.appliedOn).format(
                                          "DD MMM YY"
                                        )
                                      : "-"}{" "}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={5}>
                                  <Typography
                                    variant="body2"
                                    component="div"
                                    className="Program"
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{display:'flex'}}>
                                        <span
                                          style={{
                                            fontFamily: "Outfit-Medium",
                                          }}
                                        >
                                          Status:
                                        </span>
                                        <span
                                          style={{
                                            color:
                                              item?.status === "Disbursed"
                                                ? "#12B76A"
                                                : "#F8A31F",
                                            fontFamily: "Outfit-Medium",
                                            backgroundColor:
                                              item?.status === "Disbursed"
                                                ? "#ECFDF3"
                                                : "#FFF6E8",
                                            borderRadius:
                                              item?.status === "In Process"
                                                ? "16px"
                                                : "0px",
                                            padding: "0.2rem",
                                          }}
                                        >
                                          { item?.status}
                                        </span>
                                      </div>
                                     
                                    </div>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </AccordionSummary>

                            <AccordionDetails style={{ overflow: "auto" }}>
                              <div
                                style={{
                                  listStyleType: "none",
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "1050px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: "500",
                                      color: "black",
                                      cursor: "pointer",
                                      lineHeight: "20px",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      textAlign: "center",
                                    }}
                                    onClick={() => setShowEditField("basic-details")}
                                  >
                                    <img
                                      style={{ height: "25px", width: "25px" }}
                                      src={
                                        isImageClicked1
                                          ? Basicdetailsclicked
                                          : basicdetails
                                      }
                                      alt="basicdetails"
                                    />
                                    {stage<2? (
                                      <div
                                        style={{
                                          marginTop: "10px",
                                          borderStyle: "solid",
                                          borderColor: "#F8A31F",
                                          borderRadius: "50%",
                                          height: "21px",
                                          width: "21px",
                                          alignSelf: "center",
                                        }}
                                      ></div>
                                    ) : (
                                      <div style={{ marginTop: "10px" }}>
                                        <img
                                          style={{
                                            padding: "1px",
                                            borderStyle: "solid",
                                            borderColor: "#12B76A",
                                            borderRadius: "50%",
                                            height: "20px",
                                            width: "20px",
                                            alignSelf: "center",
                                            backgroundColor: "#12B76A",
                                          }}
                                          src={tickicon}
                                        />
                                      </div>
                                    )}

                                    <p
                                      style={{
                                        fontFamily: "Outfit-Medium",
                                        fontSize: "14px",
                                        color: isImageClicked1
                                          ? "#D32027"
                                          : "black",
                                        marginTop: "5px",
                                      }}
                                    >
                                      Basic Details
                                    </p>
                                  </div>
                                  {stage<2 ? (
                                    <div
                                      style={{
                                        borderBottom: "2px dashed #F8A31F",
                                        borderSpacing: "40px",
                                        alignSelf: "center",
                                        height: "2px",
                                        width: "120px",
                                        marginRight: "20px",
                                      }}
                                    ></div>
                                  ) : (
                                    <div style={{ marginTop: "45px" }}>
                                      <div
                                        style={{
                                          alignSelf: "center",
                                          height: "2px",
                                          width: "120px",
                                          background: "#12B76A",
                                        }}
                                      ></div>
                                    </div>
                                  )}
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginRight: "5px",
                                      fontWeight: "500",
                                      fontSize: "15px",
                                      color: "black",
                                      cursor: "pointer",
                                      lineHeight: "20px",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      textAlign: "center",
                                    }}
                                    onClick={() =>{
                                      if(!applicationData?.employmentType || !applicationData?.instituteName || (stage<4))
                                        setShowEditField('work-details')}
                                    }
                                      
                                  >
                                    <img
                                      style={{ height: "25px", width: "25px" }}
                                      src={
                                        isImageClicked2 ? incomeclicked : income
                                      }
                                      alt="income"
                                    />
                                    {(!applicationData?.employmentType || !applicationData?.instituteName || !(stage>=4))? (
                                      <div
                                        style={{
                                          marginTop: "10px",
                                          borderStyle: "solid",
                                          borderColor: "#F8A31F",
                                          borderRadius: "50%",
                                          height: "21px",
                                          width: "21px",
                                          alignSelf: "center",
                                        }}
                                      ></div>
                                    ) : (
                                      <div style={{ marginTop: "10px" }}>
                                        <img
                                          style={{
                                            padding: "1px",
                                            borderStyle: "solid",
                                            borderColor: "#12B76A",
                                            borderRadius: "50%",
                                            height: "20px",
                                            width: "20px",
                                            alignSelf: "center",
                                            backgroundColor: "#12B76A",
                                          }}
                                          src={tickicon}
                                        />
                                      </div>
                                    )}

                                    <p
                                      style={{
                                        fontFamily: "Outfit-Medium",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                        color: isImageClicked2
                                          ? "#D32027"
                                          : "black",
                                      }}
                                    >
                                      Work Details
                                    </p>
                                  </div>
                                  {(!applicationData?.employmentType || !applicationData?.instituteName || !(stage>=4)) ? (
                                    <div
                                      style={{
                                        borderBottom: "2px dashed #F8A31F",
                                        borderSpacing: "40px",
                                        alignSelf: "center",
                                        height: "2px",
                                        width: "120px",
                                        marginRight: "20px",
                                      }}
                                    ></div>
                                  ) : (
                                    <div style={{ marginTop: "45px" }}>
                                      <div
                                        style={{
                                          alignSelf: "center",
                                          height: "2px",
                                          width: "120px",
                                          background: "#12B76A",
                                        }}
                                      ></div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "15px",
                                      color: "black",
                                      cursor: "pointer",
                                      lineHeight: "20px",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      if(stage>=4){
                                        setShowEditField('sanction')
                                      }
                                      
                                    } }
                                  >
                                    <img
                                      style={{ height: "25px", width: "25px" }}
                                      src={
                                        isImageClicked5
                                          ? sanctionclicked
                                          : sanction
                                      }
                                      alt="sanction"
                                    />
                                    {!(stage >= 8) ? (
                                      <div
                                        style={{
                                          marginTop: "10px",
                                          borderStyle: "solid",
                                          borderColor: "#F8A31F",
                                          borderRadius: "50%",
                                          height: "21px",
                                          width: "21px",
                                          alignSelf: "center",
                                        }}
                                      ></div>
                                    ) : (
                                      <div style={{ marginTop: "10px" }}>
                                        <img
                                          style={{
                                            padding: "1px",
                                            borderStyle: "solid",
                                            borderColor: "#12B76A",
                                            borderRadius: "50%",
                                            height: "20px",
                                            width: "20px",
                                            alignSelf: "center",
                                            backgroundColor: "#12B76A",
                                          }}
                                          src={tickicon}
                                        />
                                      </div>
                                    )}
                                    <p
                                      style={{
                                        fontFamily: "Outfit-Medium",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                        color: isImageClicked3
                                          ? "#D32027"
                                          : "black",
                                      }}
                                    >
                                      Sanction
                                    </p>
                                  </div>
                                </div>
                                {item?.obj?.applicationProfile
                                  ?.fundSourceCode !== "FIB" ? (
                                  <>
                                    {!(stage >= 8)? (
                                      <div
                                        style={{
                                          borderBottom: "2px dashed #F8A31F",
                                          borderSpacing: "40px",
                                          alignSelf: "center",
                                          height: "2px",
                                          width: "120px",
                                          marginRight: "20px",
                                        }}
                                      ></div>
                                    ) : (
                                      <div
                                        style={{
                                          alignSelf: "center",
                                          height: "2px",
                                          width: "120px",
                                          background: "#12B76A",
                                        }}
                                      ></div>
                                    )}

                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "15px",
                                          color: "black",
                                          cursor: "pointer",
                                          lineHeight: "20px",
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          textAlign: "center",
                                        }}
                                        onClick={() =>{
                                          if(stage>=8 && stage < 15){
                                            setShowEditField("kyc")
                                          }
                                          
                                        }
                                        }
                                      >
                                        <img
                                          style={{
                                            height: "25px",
                                            width: "25px",
                                          }}
                                          src={isImageClicked3 ? kycclicked : kyc}
                                        
                                          alt="kyc"
                                        />
                                        {!(stage >= 15) ? (
                                          <div
                                            style={{
                                              marginTop: "10px",
                                              borderStyle: "solid",
                                              borderColor: "#F8A31F",
                                              borderRadius: "50%",
                                              height: "21px",
                                              width: "21px",
                                              alignSelf: "center",
                                            }}
                                          ></div>
                                        ) : (
                                          <div style={{ marginTop: "10px" }}>
                                            <img
                                              style={{
                                                padding: "1px",
                                                borderStyle: "solid",
                                                borderColor: "#12B76A",
                                                borderRadius: "50%",
                                                height: "20px",
                                                width: "20px",
                                                alignSelf: "center",
                                                backgroundColor: "#12B76A",
                                              }}
                                              src={tickicon}
                                            />
                                          </div>
                                        )}
                                        <p
                                          style={{
                                            fontFamily: "Outfit-Medium",
                                            fontSize: "14px",
                                            marginTop: "5px",
                                            color: isImageClicked4
                                              ? "#D32027"
                                              : "black",
                                          }}
                                        >
                                          KYC
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                ) : null}

                                {!(stage >=15) ? (
                                  <div
                                    style={{
                                      borderBottom: "2px dashed #F8A31F",
                                      borderSpacing: "40px",
                                      alignSelf: "center",
                                      height: "2px",
                                      width: "120px",
                                      marginRight: "20px",
                                    }}
                                  ></div>
                                ) : (
                                  <div
                                    style={{
                                      alignSelf: "center",
                                      height: "2px",
                                      width: "120px",
                                      background: "#12B76A",
                                    }}
                                  ></div>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "15px",
                                      color: "black",
                                      cursor: "pointer",
                                      lineHeight: "20px",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      textAlign: "center",
                                    }}
                                    onClick={() =>{
                                      if(stage>= 15)
                                      setShowEditField("disbursment")}
                                    } 
                                  >
                                    <img
                                      style={{ height: "25px", width: "25px" }}
                                     
                                      src={
                                        isImageClicked5
                                          ? sanctionclicked
                                          : sanction
                                      }
                                      alt="disbursal"
                                    />
                                    {!stage<=15 && !(stage===17) ? (
                                      <div
                                        style={{
                                          marginTop: "10px",
                                          borderStyle: "solid",
                                          borderColor: "#F8A31F",
                                          borderRadius: "50%",
                                          height: "21px",
                                          width: "21px",
                                          alignSelf: "center",
                                        }}
                                      ></div>
                                    ) : (
                                      <div style={{ marginTop: "10px" }}>
                                        <img
                                          style={{
                                            padding: "1px",
                                            borderStyle: "solid",
                                            borderColor: "#12B76A",
                                            borderRadius: "50%",
                                            height: "20px",
                                            width: "20px",
                                            alignSelf: "center",
                                            backgroundColor: "#12B76A",
                                          }}
                                          src={tickicon}
                                        />
                                      </div>
                                    )}
                                    <p
                                      style={{
                                        fontFamily: "Outfit-Medium",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                        color: isImageClicked5
                                          ? "#D32027"
                                          : "black",
                                      }}
                                    >
                                      Disbursment
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </AccordionDetails>
                            {showEditField === 'basic-details' && ( <BasicDetails   application={applicationData} /> )}
                            {showEditField === 'work-details' && <WorkDetails   application={applicationData} stage={stage} />}
                            {showEditField === 'sanction' && <Sanctions   application={applicationData} stage={stage} />}
                            {showEditField === 'kyc' && <Kyc   application={applicationData} stage={stage} />}
                            {showEditField==='disbursment' &&  <Disbursed  item={item} stage={stage}/>}
                            {stage===17 && <FinalMessage/>}
                          </Accordion>
                        </div>
                      )}
                    </>
            
            )})}
           
          </Box>
        ) : (
          <Box>
            <div>
              <h1
                style={{
                  textAlign: "center",
                  fontFamily: "Outfit",
                  color: "#d32028",
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  gap: "2rem",
                }}
              >
                Loading
                <div
                  className={styless.dotFlashing}
                  style={{
                    marginBottom: "0.5rem",
                  }}
                ></div>
              </h1>
            </div>
          </Box>
        )}
      </Mainpagestyle>
      <Educationcard application = {applicationsDetails?.slice(-1)[0]} newUser={applicationsDetails?.length===1} mobile={decode?.mobile}/>
      {/* <Preveiouspayment userData={rows} /> */}
    </>
  );
}

export default Applications;
