import styles from "./styles.module.css";
import Paid from "../images/no-dues-png.png";
import Logo from "../images/feemonk-logo2.png"

function NoDues() {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {/* <h1>Mandate</h1> */}
        <img src={Logo} alt="" className={styles.image} />
        <p className={styles.holdOnHeaderText}>Thank You for your Last EMI Payment!</p>
        <img src={Paid} alt="" className={styles.heroImage} />
        <div className={styles.holdOnTextContainer}>          
          <p>You do not have any Active Overdue.</p>
          {/* <p>Please login <a href="https://feemonk.com/login" className={styles.anchor}>here</a> with your registered mobile to download your receipts. </p> */}
        </div>

        <div>
          <p className={styles.holdOnHelperText}>
            If you have a problem, please contact us:
          </p>
          <p className={styles.holdOnHelperTextSmall}>hello@feemonk.com</p>
        </div>
      </div>
    </div>
  );
}

export default NoDues;
