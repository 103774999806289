import React from 'react';
import { Document, Page, Text, View,Image } from '@react-pdf/renderer';
import NumberToWords from 'number-to-words'; // Library for converting numbers to words
import FeemonkLogo from '../../../Assets/images/FeeMonk_HighRes_Logo.png'

// Font family for the receipt
const font = {
  fontFamily: 'Helvetica',
};

const Receipt = ({ company, user, receiptNo }) => {
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}/${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${currentDate.getFullYear()}`;
  const currentTime = new Date().toLocaleTimeString();
  // Convert amount to words
  const amountInWords = `${NumberToWords.toWords(user?.amount).toUpperCase()} RUPEES ONLY`;

  return (
    <Document>
      <Page size="A4">
        {/* Header */}
        <View style={{ padding: 20, ...font, fontSize: 10 }}>
          <View style={{ marginBottom: 10, flexDirection: 'column', alignItems: 'center' }}>
            <Image src={FeemonkLogo} style={{ width:80 }}/>
            <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', marginBottom: 5, textAlign: 'center' }}>{company.name}</Text>
            <Text style={{ fontSize: 10, textAlign: 'center' }}>{company.address}</Text>
            <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', textAlign: 'center', marginBottom: 10 ,marginTop: 2}}>Temporary Receipt</Text>
              <Text style={{ fontSize: 10, textAlign: 'center', marginTop: 8}}>Printed on: {formattedDate} {currentTime}</Text>
              {/* <Text style={{fontFamily: 'Helvetica-Bold'}}>Gachibowli</Text> */}
          </View>
          
          {/* Dots below the header */}
          <View style={{ marginTop: 10, marginBottom: 10, height: 1, backgroundColor: 'black' }} />

          {/* Body */}
          <View style={{ marginBottom: 10}}>
          {/* <View style={{ flexDirection: 'row', paddingBottom: 10,borderBottom:'1px dashed #000' }}>
              <Text style={{fontFamily: 'Helvetica-Bold'}}>Receipt No: {receiptNo}</Text>
              <Text>Date of Payment: {user.dateOfPayment}</Text>
            </View> */}
            <View style={{ marginBottom: 10,display:'flex',flexDirection: 'row',rowGap:10}}>
                <Text style={{width:'25%'}}>Receipt No</Text><Text style={{width:'5%'}}>:</Text> <Text >{receiptNo}</Text>
              </View>
              <View style={{ marginBottom: 10,display:'flex',flexDirection: 'row'}}>
                <Text style={{width:'25%'}}>Date of Payment</Text><Text style={{width:'5%'}}>:</Text> <Text >{user.dateOfPayment}</Text>
              </View>
              
              <View style={{ marginBottom: 10  ,display:'flex',flexDirection: 'row'}}>
                <Text style={{width:'25%'}}>Received with thanks from</Text><Text style={{width:'5%'}}>:</Text> <Text> {user?.username}</Text>
              </View>
              
              <View style={{ marginBottom: 10  ,display:'flex',flexDirection: 'row'}}>
                <Text style={{width:'25%'}}>Total Amount</Text> <Text style={{width:'5%'}}>:</Text><Text>{user?.amount?.toLocaleString('en-US', { minimumFractionDigits: 0 })}</Text>
              </View>
            
              <View style={{ marginBottom: 10  ,display:'flex',flexDirection: 'row', flexWrap: 'wrap'}}>
                <Text style={{width:'25%'}}>Amount in words</Text>
                <Text style={{width:'5%'}}>:</Text>
                <Text style={{flex: 1}}>{amountInWords}</Text>
              </View>
            </View>

          {/* Body */}


          {/* Dots after the body */}
          <View style={{ marginTop: 10, marginBottom: 10, height: 1, backgroundColor: 'black' }} />

          {/* Footer */}
          <View style={{ marginTop: 20, flexDirection: 'row', justifyContent: 'center', fontWeight: 'bold',alignItems:'center' }}>
            <Text style={{ fontFamily: 'Helvetica'}}>***This is a system generated receipt. No signature is required.***</Text>
         
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Receipt;
