import React from 'react'

export default function interest() {
  return (
    <div>
    <div class="container mt-5">
        <h3 class="mt-5 text-center">INTEREST RATE POLICY</h3>
        <h3 class="text-center mt-3">GLAZE BARTER PRIVATE LIMITED</h3>

        <h5 class="mt-5">INTEREST RATE POLICY</h5>

        <dl class="mt-3">
            <dt>1.Preamble:</dt>
            <dd class="mt-3">As per the Master Direction - Non-Banking Financial Company - Systemically Important Non-Deposit taking Company and Deposit taking Company (Reserve Bank) Directions, 2016 (RBI/DNBR/2016-17/45 Master Direction DNBR. PD. 008/03.10.119/2016-17) issued by the Reserve Bank of India, Board of each applicable Non-Banking Financial Company shall approve an Interest rate model that is applicable for the Company, taking in to account relevant factors such as cost of funds, margin and risk premium etc., and determine the rate of interest to be charged for loans and advances. Further, the directive states that the rate of interest and the approach for gradation of risk and the rationale for charging different rates of interest for different category of borrowers should be communicated to the borrower in the sanction letters issued to them.</dd>
        </dl>
        <dl>
            <dt>2.Objective of the policy</dt>
            <ul class="mt-2">
                <li>To arrive at the benchmark rates to be used for different category of borrower segments and to decide on the principles and approach of charging spreads to arrive at final rates charged from borrowers.</li>
                <li>Communicate the annualised rate of interest to the borrower along with the approach for gradation of risk and rationale for charging different rates of interest to different categories of borrowers.</li>
                <li> Make available the rates of interest and the approach for gradation of risks on the web- site of the companies.</li>
            
            </ul>
        </dl>
<dl>
    <dt>3.Rate of interest:</dt>
    <dd class="mt-3">The Company intimates the borrower, the loan amount, the rate of interest and any other fees which is applicable for the loan at the time of sanction of the loan along with the tenure, the amount and the due dates of the monthly instalments.</dd>
    <dd class="mt-3">The rate of interest is arrived at based on the weighted average cost of funds, average customer acquisition cost, administrative and operational costs, risk premium and profit margin.</dd>
    <dd class="mt-3">
        The rates of interest are subject to change as the situation warrants and are subject to the discretion of the management and/or changes to extraneous cost factors which has a say in the setting up of the interest rate.</dd>

</dl>

<dl>
    <dt>4.Approach for Risk Gradation:</dt>
    <dd class="mt-3">Glaze Barter Pvt. Ltd. grants credit facilities only to those customers who have both the intention and the ability to discharge their obligations. To execute smooth underwriting process the Company carries out different processes as per Know Your Customer guidelines and allocates credit grade for each customer. When assessing credit transactions, the Company focuses on critical principles like history of the Company or Borrower, Financial Leverage, Liquidity and Sources of Cash, Profitability of Operations and Collateral being provided. The determination of a customer’s credit grading is generally distinguished by the asset type and its use and is mostly based on four general categories, Character, Capacity, Capital and Collateral. The individual assessment criteria for the customer credit grading can be classified into each of these categories. All credit submissions will be classified into four categories: “A” – Excellent, “B” – Superior, “C” – Acceptable and “D” – Marginal.
    </dd>
</dl>

<dl>
    <dt>5.Processing /documentation and other charges</dt>
    <dd class="mt-3">
        All processing / documentation and other charges recovered are expressly stated in the Loan documents. They vary based on the loan product, exposure limit, customer segment, geographical location and generally represent the cost incurred in rendering the services to the customers. The practices followed by other competitors in the market would also be taken into consideration while deciding the charges.
    </dd>
</dl>

<dl>
    <dt>6.Penal Interest /Late payment charges</dt>
<dd class="mt-3">
    <ul>
        <li>Besides normal interest, overdue interest, the Company may collect penal interest / late payment charges for any delay or default in making payments of any dues. These penal interest / late payment charges for different products or facilities would be decided by the Company from time to time.</li>
        <li class="mt-2">No claims for refund or waiver of such charges/ penal interest / additional interest would normally be entertained by the Company and it is the sole discretion of the Company to deal with such requests if any.</li>
    </ul>
</dd>
</dl>
<dl>
    <dt>7.Review of Policy</dt>
    <dd class="mt-3">The Policy shall be reviewed once in a year or in between if required due to changes required in the model, for example any addition/deletion of a particular component forming part of benchmark calculation.</dd>
</dl>
<dl>
    <dt>8.Disclosure</dt>
    <dd class="mt-3">This Policy will be made available on the website of the Company or published in the newspapers in
        accordance with the Company’s Fair Practices Code and the guidelines of RBI.</dd>
</dl>

    </div>
    </div>
  )
}
